import type { ModuleError, ModuleStatus } from 'types/ModuleStatus';
import { User } from 'types/User';
import { MODULE_STATUS } from 'constants/modules';
import { UserActionNames } from 'modules/user/userActions';
import { UserPermission } from 'constants/userPermission';

export interface UserStore extends User {
  status: ModuleStatus;
  error: ModuleError;
  availableStatuses: number[];
  permissionsKeys: UserPermission[];
}

function getInitialState(): UserStore {
  return {
    status: MODULE_STATUS.Idle,
    error: null,
    color: '',
    companyPhone: '',
    devicesInfo: { android: false, ios: false },
    availableStatuses: [],
    email: '',
    hasDevices: false,
    id: 0,
    localId: 0,
    name: '',
    avatar: null,
    regDate: '',
    role: '',
    surname: '',
    timezone: '',
    intercom: null,
    useMeridianTime: true,
    permissionsKeys: [],
    features_toggle: {
      new_analytics: false,
      new_calendar: false,
    },
    lastPostsQueryParams: {
      dateFrom: '',
      dateTo: '',
      orderDirection: '',
      assignedUsers: [],
      labels: [],
      profileGroups: [],
      profiles: [],
      projects: [],
      pseudoTypes: [],
      statuses: [],
    },
  };
}

function userReducer(state = getInitialState(), action: any) {
  switch (action.type) {
    case UserActionNames.SET_MODULE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case UserActionNames.SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case UserActionNames.SET_USER_PERMISSIONS:
      return {
        ...state,
        permissionsKeys: action.payload,
      };
    case UserActionNames.SET_ALLOWED_POST_STATUSES:
      return {
        ...state,
        availableStatuses: action.payload,
      };
    case UserActionNames.SAVE_LAST_FILTER_STATE:
      return {
        ...state,
        lastPostsQueryParams: action.payload,
      };
    default:
      return state;
  }
}

export default userReducer;
