import { Checkbox, DropdownMenu, Icon } from '@kontentino/ui';
import { faBolt } from '@fortawesome/pro-regular-svg-icons/faBolt';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import WorkflowStatusIcon from 'app/components/workflowStatus/WorkflowStatusIcon';
import {
  PostStatus,
  STATUS_LABEL,
} from '@kontentino/kontentino-constants/Posts';
import { useUser } from 'modules/user/userSelector';
import { useRef, useState } from 'react';
import { PostStatuses } from 'types/Post';
import { useAdsPlannerContainer } from 'components/adsPlanner/context';
import _debounce from 'lodash.debounce';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import AdsPlannerConfig from 'app/config/adsPlanner';

const AdsPlannerFiltersStatusSubmenu = () => {
  const { availableStatuses } = useUser();
  const { filters, changeSelectedWorkflowStatuses } = useAdsPlannerContainer();
  const [selectedValues, setSelectedValues] = useState<PostStatuses>(
    filters.selectedWorkflowStatuses,
  );

  const notifyChangedStatus = useRef(
    _debounce((data: PostStatuses) => {
      changeSelectedWorkflowStatuses(data);
    }, AdsPlannerConfig.FILTER_DELAY),
  );

  useEffectOnce(() => {
    return () => {
      notifyChangedStatus.current.cancel();
    };
  });

  const handleChangeStatus = (status: PostStatus) => {
    setSelectedValues((prevSelectedValues) => {
      const statuses = togglePrimitiveItemInArray<PostStatus>(
        status,
        prevSelectedValues,
      );

      notifyChangedStatus.current(statuses);
      return [...statuses];
    });
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>
        <DropdownMenu.Item data-name="" data-cy="">
          <Icon icon={faBolt} />
          Status
          <Icon size="sm" className="tw-ml-auto" icon={faChevronRight} />
        </DropdownMenu.Item>
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className="tw-z-10 tw-max-h-[70vh] tw-overflow-y-auto">
          {availableStatuses.map((status) => (
            <div key={`ad-status-${status}`}>
              <DropdownMenu.Item
                onSelect={() => handleChangeStatus(status as PostStatus)}
              >
                <Checkbox
                  size="small"
                  onChange={() => null}
                  checked={selectedValues.includes(status as PostStatus)}
                />
                <WorkflowStatusIcon status={status} />
                {STATUS_LABEL[status]}
              </DropdownMenu.Item>
            </div>
          ))}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

export default AdsPlannerFiltersStatusSubmenu;
