import { STATUS } from '@kontentino/kontentino-constants/Posts';
import { UserPermission } from 'constants/userPermission';
import { POST_STATUS_BY_USER_PERMISSION } from 'app/modules/posts/constants';

export const SORTED_STATUSES = [
  STATUS.DRAFT,
  STATUS.COMPLETED,
  STATUS.WAITING_FOR,
  STATUS.DONE,
  STATUS.WAITING_FOR_INTERNAL_APPROVAL,
  STATUS.REJECTED,
  STATUS.INTERNAL_APPROVED,
  STATUS.AT_CLIENT,
  STATUS.REJECTED_BY_CLIENT,
  STATUS.APPROVED,
  STATUS.IS_SCHEDULING,
  STATUS.SCHEDULED,
  STATUS.POSTED,
  STATUS.EXPORTED,
  STATUS.EXPORT_FAILED,
] as number[];

export const getStatusesByUserPermissions = (
  permissionsKeys: UserPermission[],
): number[] => {
  if (permissionsKeys.includes(UserPermission.POST_LIST_ALL)) {
    return Object.values(POST_STATUS_BY_USER_PERMISSION).filter(
      (status) =>
        !([STATUS.EXPORT_FAILED, STATUS.IS_SCHEDULING] as number[]).includes(
          status,
        ),
    );
  }

  const statusesByPermissions = permissionsKeys
    .filter((permissionKey) => permissionKey in POST_STATUS_BY_USER_PERMISSION)
    .map((permissionKey) => POST_STATUS_BY_USER_PERMISSION[permissionKey]);

  return statusesByPermissions.sort((statusA, statusB) => {
    const indexA = SORTED_STATUSES.indexOf(statusA);
    const indexB = SORTED_STATUSES.indexOf(statusB);
    return indexA - indexB;
  });
};
